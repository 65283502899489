import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from 'app/shared/services/user.service';
import { Observable, of, switchMap } from 'rxjs';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { AuthUser } from 'app/core/models/auth_user.model';
import { ChangePasswordRequest } from 'app/core/models/change_password_request.model';
import { BaseService } from '../../shared/services/base.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {
    private _authenticated: boolean = false;
    private signInData: { email: string; password: string } | null = null;


    constructor(
        public _http: HttpClient,
        public _router: Router,
        private _userService: UserService,
        private _localStorage: LocalStorageService
    ) {
        super(_router);
    }

    // Loguea al usuario.
    signIn(email: string, password: string, captcha: boolean): Observable<any> {
        const url = `${this._api}/api/v1/login`;

        return this._http
            .post(url, {
                email: email,
                password: password,
                captcha: captcha
            })
            .pipe(
                switchMap((response: any) => {
                    // Store the access token in the local storage
                    if(response.data != null && response.data.token){
                        this._localStorage.accessToken = response.data.token;
    
                        // Set the authenticated flag to true
                        this._authenticated = true;
    
                        // Store the user on the user service
                        this._userService.user = AuthUser.createOne(
                            response.data,
                            new AuthUser()
                        );
    
                        this._userService.user$.subscribe((user: AuthUser) => {
                            this._localStorage.user = JSON.stringify(user.toJson());
                        });
                    }

                    // Return a new observable with the response
                    return of(response);
                })
            );
    }

    /** Desloguea el usuario */
    signOut(): void {
        // Set the authenticated flag to false
        this._authenticated = false;

        // Remove the access token from the local storage
        this._localStorage.clearAll();
    }

    /** Envia la solicitud para que el usuario cambie su contraseña */
    forgotPasswordRequest(email: string): Observable<any> {
        const url = `${this._api}/api/v1/password/mail`;

        return this._http
            .post(url, {
                email: email,
            })
            .pipe(
                switchMap((response: any) => {
                    return of(response);
                })
            );
    }

    changePassword(request: ChangePasswordRequest) {
        const url = `${this._api}/api/v1/password/reset`;
        return this._http.post(url, request.toJson()).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }

    /** Valida si el usuario esta logueado o no */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (!this._localStorage.accessToken || !this._localStorage.user) {
            return of(false);
        } else {
            this._authenticated = true;

            // Store the user on the user service
            this._userService.user = AuthUser.newModelFromStorage(
                this._localStorage.user.toString()
            );

            return of(true);
        }
        // Check the access token availability

        // If the access token exists, and it didn't expire, sign in using it
        // return this.signInUsingToken();
    }

    verifyCode(code: number, email: string, password: string,): Observable<any> {
        const url = `${this._api}/api/v1/verify-code`;
        return this._http
            .post(url, { 
                email: email,
                password: password,
                verification_code: +code, 
            })
            .pipe(
                switchMap((response: any) => {
                    console.log('respuesta del verify code', response)
                    // Store the access token in the local storage
                    this._localStorage.accessToken = response.data.token;
    
                    // Set the authenticated flag to true
                    this._authenticated = true;
    
                    // Store the user on the user service
                    this._userService.user = AuthUser.createOne(
                        response.data,
                        new AuthUser()
                    );
    
                    this._userService.user$.subscribe((user: AuthUser) => {
                        this._localStorage.user = JSON.stringify(user.toJson());
                    });
    
                    // Return a new observable with the response
                    return of(response);
                })
            );
        }

    setSignInData(email: string, password: string): void {
        this.signInData = { email, password };
    }

    getSignInData(): { email: string; password: string } | null {
        return this.signInData;
    }

    clearSignInData(): void {
        this.signInData = null;
    }

    validateCaptcha(token: any): Observable<unknown> {
        const url = `${this._api}/api/v1/validate-captcha`;
        return this._http.post(url, {captcha: token})
          .pipe(
            switchMap((response: any) => {
                return of(response);
            })
          );
      }
}
