import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

export const appRoutes: Route[] = [
    { path: '', pathMatch: 'full', redirectTo: 'home' },

    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'home' },

    // Paginas que no requieren que el usuario este logueado.
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.routes'
                    ),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.routes'
                    ),
            },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.routes'),
            },
            {
                path: 'verify-code',
                loadChildren: () =>
                    import(
                        'app/modules/auth/verify-code/verify-code.routes'
                    ),
            },
        ],
    },

    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.routes'),
            },
        ],
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        // resolve: {
        //     initialData: initialDataResolver,
        // },
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    import('app/modules/admin/example/example.routes'),
            },
            {
                path: 'products',
                loadChildren: () =>
                    import('app/modules/product/products.routes'),
            },
            {
                path: 'planogram',
                loadChildren: () =>
                    import('app/modules/planogram/planogram.routes'),
            },
            {
                path: 'revisions',
                loadChildren: () =>
                    import('app/modules/revision/revisions.routes'),
            },
            {
                path: 'orders',
                loadChildren: () => import('app/modules/order/order.routes'),
            },
            {
                path: 'movements',
                loadChildren: () =>
                    import('app/modules/movement/movement.routes'),
            },
            {
                path: 'stock',
                loadChildren: () => import('app/modules/stock/stock.routes'),
            },
            {
                path: 'oven',
                loadChildren: () => import('app/modules/oven/oven.routes'),
            },
        ],
    },
    { path: '**', pathMatch: 'full', redirectTo: 'home' },
];
