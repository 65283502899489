// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr: false,
    api: 'https://test-fabricacion-api.pedidosdeheza.com.ar',
    test: true,
    /* Agregar archivo proxy.conf.json con la url del servicio de api para evitar CORS
        {
            "/api/*": {
            "target": "http://localhost:98/api/v1",
            "secure": false,
            "logLevel": "debug",
            "changeOrigin": true
            }
        }
    */
    version: '4.0',
    receptionDateDayFrom: '10', // cantidad de dias dsp que puede cargar la fecha estimada de llegada
    recaptchaKey: '6LdPnaYqAAAAAPmuama78qNyxYbFqZ-4pdA20A-e'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
